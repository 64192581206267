import {
  faPen,
  faTrash,
  faGripVertical,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
const DraggableTableRow = ({
  ignoredFields,
  index,
  keys,
  hideActions,
  item,
  eAF,
  onUpdate,
  onDelete,
  data,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: item?._id,
    animateLayoutChanges: ({ isDragging }) => !isDragging,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  return (
    <tr ref={setNodeRef} style={style}>
      {isDragging ? (
        <th colSpan={keys?.length + 1} className="bg-slate-100">
          &nbsp;
        </th>
      ) : (
        <>
          <th className="!px-[16px]">
            <FontAwesomeIcon
              className={`text-xl  mr-2 ${
                isDragging ? 'cursor-grabbing' : 'cursor-grab'
              }`}
              icon={faGripVertical}
              {...attributes}
              {...listeners}
            />
            {index + 1}
          </th>
          {keys?.map((key) => {
            return (
              !ignoredFields.includes(key) && (
                <td key={key}>{item[key] ?? '-'}</td>
              )
            );
          })}
          <td className="!text-right !px-[16px]">
            {eAF && eAF?.length > 0 && eAF.map((field) => field(data[index]))}
            {!hideActions && (
              <>
                <button onClick={() => onUpdate(data[index])} className="mx-4">
                  <FontAwesomeIcon className="text-xl" icon={faPen} />
                </button>
                <button onClick={() => onDelete(data[index])}>
                  <FontAwesomeIcon className="text-xl" icon={faTrash} />
                </button>
              </>
            )}
          </td>
        </>
      )}
    </tr>
  );
};

export default DraggableTableRow;

import Axios from 'axios';
import { useEffect, useState } from 'react';
import Config from '../Config';
import { toast } from 'react-toastify';
import Modal from '../components/common/Modal';
import Table from '../components/common/Table';

const Role = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [meta, setMeta] = useState(null);
  const [roles, setRoles] = useState([]);
  const [newRole, setNewRole] = useState('');
  const [selectedRole, setSelectedRole] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const fields = {
    name: 'Naam',
    users: 'Gebruikers',
  };

  const addRole = async (event) => {
    event.preventDefault();
    try {
      await Axios.post(`${Config.apiUrl}/api/v1/roles`, {
        name: newRole?.trim(),
      });
      setNewRole('');
      toast('Role added successfully');
      await fetchData();
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  const fetchData = async () => {
    try {
      const {
        data: { data, meta },
      } = await Axios.get(
        `${Config.apiUrl}/api/v1/roles?page=${page}&limit=${limit}`,
      );

      if (data?.length === 0 && page > 1) {
        setPage((prev) => prev - 1);
      }

      setMeta(meta);
      setRoles(data);
    } catch (error) {
      toast('Failed to fetch roles. Please try again later.', {
        type: 'error',
      });
    }
  };

  const onUpdate = (role) => {
    setSelectedRole(role);
    setShowUpdateModal(true);
  };

  const onDelete = async (role) => {
    setSelectedRole(role);
    setShowDeleteModal(true);
  };

  const deleteRole = async () => {
    try {
      await Axios.delete(`${Config.apiUrl}/api/v1/roles/${selectedRole?.id}`);

      setRoles((prevRoles) =>
        prevRoles.filter((o) => o.id !== selectedRole?.id),
      );
      setSelectedRole(null);
      setShowDeleteModal(false);

      await fetchData();
      toast('Role deleted successfully');
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  const handleUpdateRole = (event) => {
    let name = event.target.value;

    setSelectedRole({
      ...selectedRole,
      name,
    });
  };

  const updateRole = async (event) => {
    event.preventDefault();

    try {
      await Axios.patch(`${Config.apiUrl}/api/v1/roles/${selectedRole?.id}`, {
        name: selectedRole?.name?.trim(),
      });

      setRoles((prev) => {
        let index = prev.findIndex((o) => o.id === selectedRole?.id);

        prev[index] = {
          ...selectedRole,
        };
        setSelectedRole(null);
        setShowUpdateModal(false);

        return prev;
      });

      toast('Role updated successfully');
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [page, limit]);

  return (
    <div className="h-screen pt-32 px-4">
      <form className="flex flex-wrap gap-4" onSubmit={addRole}>
        <input
          className="w-[400px] text-black rounded input bg-transparent"
          placeholder="Voer de naam van de rol in"
          value={newRole}
          required
          onChange={(event) => setNewRole(event.target.value)}
        />
        <button type="submit" className="auth-button w-[200px] mb-4">
          Toevoegen
        </button>
      </form>
      {showUpdateModal && (
        <Modal title="Rol" close={() => setShowUpdateModal(false)}>
          <form className="flex space-x-4" onSubmit={updateRole}>
            <input
              className="w-[400px] text-black rounded input bg-transparent"
              placeholder="Voer de naam van de rol in"
              value={selectedRole?.name}
              onChange={handleUpdateRole}
              required
            />
            <button type="submit" className="auth-button w-[200px]">
              Update
            </button>
          </form>
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          title="Rol"
          action="Verwijderen"
          close={() => setShowDeleteModal(false)}
          confirm={deleteRole}
          type="delete"
          name={selectedRole?.name}
        />
      )}
      <Table
        fields={fields}
        data={roles}
        actions={{ onUpdate, onDelete }}
        hasPagination={true}
        page={page}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        meta={meta}
      />
    </div>
  );
};
export default Role;

import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import Config from '../../Config';
import { toast } from 'react-toastify';
import Tag from '../common/Tag';

const QuestionForm = ({
  question,
  onSubmit = () => {},
  type = 'add',
  currentUser,
}) => {
  const [newQuestion, setNewQuestion] = useState(question);
  const [topics, setTopoics] = useState([]);
  const [choice, setChoice] = useState('');
  const [category] = useState([
    {
      name: 'Snel',
      value: 'quick',
    },
    {
      name: 'Uitgebreid',
      value: 'extensive',
    },
  ]);
  const [types] = useState([
    {
      name: 'Bereik',
      value: 'range',
    },
    {
      name: 'Ja/Nee',
      value: 'yes/no',
    },
    {
      name: 'Multiple choice',
      value: 'multiple',
    },
  ]);

  const fetchTopics = async () => {
    try {
      const {
        data: { data },
      } = await Axios.get(
        `${Config.apiUrl}/api/v1/survey-topics/all?organization=${currentUser?.organization?._id}`,
      );

      setTopoics(data);
    } catch (error) {
      console.log('errr', error);
      toast('Failed to fetch data. Please try again later.', {
        type: 'error',
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validate(newQuestion)) return;

    onSubmit(newQuestion, setNewQuestion);
  };

  const validate = (question) => {
    const { topic } = question;
    let message = '';

    if (!topic) {
      message = 'Please select a topic';
    }

    if (message) {
      toast(message, {
        type: 'error',
      });
    }

    return message.length === 0;
  };

  const onTypeChange = (event) => {
    setNewQuestion({
      ...newQuestion,
      type: event.target.value,
      property: {
        startText: '',
        endText: '',
        advice: { yes: '', no: '' },
        choices: [],
      },
    });

    setChoice('');
  };

  const onAddChoice = () => {
    const formattedChoice = choice.toLowerCase();

    if (!formattedChoice) return;

    if (newQuestion.property.choices.includes(formattedChoice)) {
      toast('Choice already exist', { type: 'info' });
      return;
    }

    setNewQuestion({
      ...newQuestion,
      property: {
        ...newQuestion.property,
        choices: [...newQuestion.property.choices, formattedChoice],
      },
    });
    setChoice('');
  };

  const onRemoveChoice = (value) => {
    const filteredChoices = newQuestion.property.choices.filter(
      (choice) => choice !== value,
    );

    setNewQuestion({
      ...newQuestion,
      property: { ...newQuestion.property, choices: [...filteredChoices] },
    });
  };

  useEffect(() => {
    fetchTopics();
    // eslint-disable-next-line
  }, []);

  return (
    <form
      className={`grid grid-cols-2 gap-4 ${
        type !== 'update' && 'w-full md:w-[600px]'
      }`}
      onSubmit={handleSubmit}
    >
      <input
        className="w-full text-black rounded input bg-transparent"
        placeholder="Title"
        required
        value={newQuestion?.title || ''}
        onChange={(event) =>
          setNewQuestion({ ...newQuestion, title: event.target.value })
        }
      />
      <select
        className="select rounded"
        value={newQuestion?.category}
        onChange={(event) =>
          setNewQuestion({ ...newQuestion, category: event.target.value })
        }
        required
      >
        <option value="">Selecteer vragenlijst</option>
        {category.map((item, index) => (
          <option key={index} value={item.value}>
            {item.name}
          </option>
        ))}
      </select>
      <select
        className="select rounded"
        value={newQuestion?.topic}
        onChange={(event) =>
          setNewQuestion({ ...newQuestion, topic: event.target.value })
        }
        required
      >
        <option value="">Selecteer onderwerp</option>
        {topics.map((item, index) => (
          <option key={index} value={item._id}>
            {item.title}
          </option>
        ))}
      </select>
      <select
        className="select rounded"
        value={newQuestion?.type}
        onChange={(event) => onTypeChange(event)}
        required
      >
        <option value="">Selecteer type</option>
        {types.map((item, index) => (
          <option key={index} value={item.value}>
            {item.name}
          </option>
        ))}
      </select>
      {!newQuestion?.type ||
        (newQuestion?.type === 'range' && (
          <>
            <input
              className="w-full text-black rounded input bg-transparent"
              placeholder="Start tekst"
              required
              value={newQuestion.property?.startText || ''}
              onChange={(event) =>
                setNewQuestion({
                  ...newQuestion,
                  property: {
                    ...newQuestion.property,
                    startText: event.target.value,
                  },
                })
              }
            />
            <input
              className="w-full text-black rounded input bg-transparent"
              placeholder="Eind tekst"
              required
              value={newQuestion.property?.endText || ''}
              onChange={(event) =>
                setNewQuestion({
                  ...newQuestion,
                  property: {
                    ...newQuestion.property,
                    endText: event.target.value,
                  },
                })
              }
            />
          </>
        ))}

      {newQuestion?.type && newQuestion?.type === 'yes/no' && (
        <>
          <input
            className="w-full text-black rounded input bg-transparent col-span-2"
            placeholder="Advies voor ja"
            required
            value={newQuestion.property?.advices?.yes || ''}
            onChange={(event) =>
              setNewQuestion({
                ...newQuestion,
                property: {
                  ...newQuestion.property,
                  advices: {
                    ...(newQuestion.property?.advices || {}),
                    yes: event.target.value,
                  },
                },
              })
            }
          />
          <input
            className="w-full text-black rounded input bg-transparent col-span-2"
            placeholder="Advies voor nr"
            required
            value={newQuestion.property?.advices?.no || ''}
            onChange={(event) =>
              setNewQuestion({
                ...newQuestion,
                property: {
                  ...newQuestion.property,
                  advices: {
                    ...(newQuestion.property?.advices || {}),
                    no: event.target.value,
                  },
                },
              })
            }
          />
        </>
      )}

      {newQuestion?.type && newQuestion?.type === 'multiple' && (
        <>
          <div className="col-span-2 flex flex-row gap-2">
            <input
              placeholder="Keuze invoeren"
              className="w-full text-black rounded input bg-transparent"
              value={choice}
              onChange={(event) => setChoice(event.target.value)}
            />
            <button
              type="button"
              className="auth-button float-right"
              onClick={onAddChoice}
            >
              +
            </button>
          </div>

          {newQuestion?.property?.choices?.length > 0 && (
            <div className="flex flex-row gap-2 flex-wrap col-span-2">
              {newQuestion?.property?.choices?.map((choice) => (
                <Tag
                  label={choice}
                  key={choice}
                  onRemove={() => onRemoveChoice(choice)}
                />
              ))}
            </div>
          )}
        </>
      )}

      <button type="submit" className="auth-button mb-4">
        {type === 'update' ? 'Update' : 'Toevoegen'}
      </button>
    </form>
  );
};

export default QuestionForm;

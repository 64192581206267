import Axios from 'axios';
import { useEffect, useState } from 'react';
import Config from '../Config';
import { toast } from 'react-toastify';
import Modal from '../components/common/Modal';
import Table from '../components/common/Table';
import { useAuth0 } from '../util/react-auth0-wrapper';

const Organization = ({ currentUser }) => {
  const { setAppUser } = useAuth0();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [meta, setMeta] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [newOrganization, setNewOrganization] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const fields = {
    name: 'Naam',
    users: 'Gebruikers',
    projects: 'Projecten',
  };

  const addOrganization = async (event) => {
    event.preventDefault();
    try {
      await Axios.post(`${Config.apiUrl}/api/v1/organizations`, {
        name: newOrganization?.trim(),
      });
      setNewOrganization('');
      await fetchData();
      toast('Organization added successfully');
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  const fetchData = async () => {
    try {
      const {
        data: { data, meta },
      } = await Axios.get(
        `${Config.apiUrl}/api/v1/organizations?page=${page}&limit=${limit}`,
      );

      if (data?.length === 0 && page > 1) {
        setPage((prev) => prev - 1);
      }

      setMeta(meta);
      setOrganizations(data);
    } catch (error) {
      toast('Failed to fetch organizations. Please try again later.', {
        type: 'error',
      });
    }
  };

  const onUpdate = (organization) => {
    setSelectedOrganization(organization);
    setShowUpdateModal(true);
  };

  const onDelete = async (organization) => {
    setSelectedOrganization(organization);
    setShowDeleteModal(true);
  };

  const deleteOrganiztion = async () => {
    try {
      await Axios.delete(
        `${Config.apiUrl}/api/v1/organizations/${selectedOrganization?._id}`,
      );

      setOrganizations((prevOrganizations) =>
        prevOrganizations.filter((o) => o._id !== selectedOrganization?._id),
      );

      if (currentUser?.role?.name === 'super admin') {
        let org = JSON.parse(localStorage.getItem('sa-organization') || '{}');

        if (org?._id === selectedOrganization?._id) {
          localStorage.removeItem('sa-organization');
          setAppUser((prev) => {
            console.log('prev', prev);
            let { organization, ...data } = prev;
            return data;
          });
        }
      }

      setSelectedOrganization(null);
      setShowDeleteModal(false);
      await fetchData();
      toast('Organization deleted successfully');
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  const handleUpdateOrganization = (event) => {
    let name = event.target.value;

    setSelectedOrganization({
      ...selectedOrganization,
      name,
    });
  };

  const updateOrganization = async (event) => {
    event.preventDefault();

    try {
      await Axios.patch(
        `${Config.apiUrl}/api/v1/organizations/${selectedOrganization?._id}`,
        {
          name: selectedOrganization?.name?.trim(),
        },
      );

      if (currentUser?.role?.name === 'super admin') {
        let org = JSON.parse(localStorage.getItem('sa-organization') || '{}');

        if (org?._id === selectedOrganization?._id) {
          localStorage.setItem(
            'sa-organization',
            JSON.stringify(selectedOrganization),
          );
          setAppUser((prev) => {
            return {
              ...prev,
              organization: selectedOrganization,
            };
          });
        }
      }

      setOrganizations((prev) => {
        let index = prev.findIndex((o) => o._id === selectedOrganization?._id);

        prev[index] = {
          ...selectedOrganization,
        };
        setSelectedOrganization(null);
        setShowUpdateModal(false);

        return prev;
      });

      toast('Organization updated successfully');
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [page, limit]);

  return (
    <div className="h-screen pt-32 px-4">
      <form className="flex gap-4 flex-wrap" onSubmit={addOrganization}>
        <input
          className="w-[400px] text-black rounded input bg-transparent"
          placeholder="Voer de naam van de organisatie in"
          required
          value={newOrganization}
          onChange={(event) => setNewOrganization(event.target.value)}
        />
        <button type="submit" className="auth-button w-[200px] mb-4">
          Toevoegen
        </button>
      </form>
      {showUpdateModal && (
        <Modal title="Organisatie" close={() => setShowUpdateModal(false)}>
          <form className="flex space-x-4" onSubmit={updateOrganization}>
            <input
              className="w-[400px] text-black rounded input bg-transparent"
              placeholder="Voer de naam van de organisatie in"
              value={selectedOrganization?.name}
              onChange={handleUpdateOrganization}
              required
            />
            <button type="submit" className="auth-button w-[200px]">
              Update
            </button>
          </form>
        </Modal>
      )}
      {showDeleteModal && (
        <Modal
          title="Organisatie"
          action="Verwijderen"
          close={() => setShowDeleteModal(false)}
          confirm={deleteOrganiztion}
          type="delete"
          name={selectedOrganization?.name}
        />
      )}
      <Table
        fields={fields}
        data={organizations}
        actions={{ onUpdate, onDelete }}
        hasPagination={true}
        page={page}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        meta={meta}
      />
    </div>
  );
};
export default Organization;

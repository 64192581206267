const Progress = ({ title, value, max }) => {
  return (
    <div className="w-full max-w-[200px] m-w-[120px]">
      <div className="relative h-3 rounded-full bg-gray-300">
        <div
          className="h-full transition-width ease-in-out duration-300 rounded-full bg-green-600"
          style={{
            width: `${(value / max) * 100}%`,
            maxWidth: `${max * 10 + (100 - max * 10)}%`,
          }}
        ></div>
      </div>
      <span className="font-bold text-sm md:text-lg">{title}</span>
    </div>
  );
};
export default Progress;

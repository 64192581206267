import Axios from 'axios';
import { useEffect, useState } from 'react';
import Config from '../Config';
import { toast } from 'react-toastify';
import Modal from '../components/common/Modal';
import AlertMessage from '../components/common/AlertMessage';
import DraggableTable from '../components/common/DraggableTable';

const Topic = ({ currentUser }) => {
  const initialTopic = {
    title: '',
    parentId: '',
    category: '',
  };

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [meta, setMeta] = useState(null);
  const [topics, setTopics] = useState([]);
  const [topicOptions, setTopicOptions] = useState([]);
  const [allTopics, setAllTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(initialTopic);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOrganizationAlert, setShowOrganizationAlert] = useState(false);
  const [isChildTopic, setisChildTopic] = useState(0);
  const [category] = useState([
    {
      name: 'Snel',
      value: 'quick',
    },
    {
      name: 'Uitgebreid',
      value: 'extensive',
    },
  ]);

  const warningMessage =
    'Je account is nog niet gekoppeld aan een organisatie. Neem contact op met je contactpersoon om verder te gaan.';

  const fields = {
    title: 'Titel',
    questions: 'Vragen',
    parent: 'Parent',
  };

  const addTopic = async (event) => {
    if (showOrganizationAlert) return;

    await Axios.post(`${Config.apiUrl}/api/v1/survey-topics`, {
      title: selectedTopic?.title,
      parent: selectedTopic?.parentId,
      category: selectedTopic?.category,
      organization: currentUser?.organization?._id,
    });

    toast('Topic added successfully');
  };

  const fetchData = async () => {
    try {
      const {
        data: { data, meta },
      } = await Axios.get(
        `${Config.apiUrl}/api/v1/survey-topics?page=${page}&limit=${limit}`,
      );

      if (data?.length === 0 && page > 1) {
        setPage((prev) => prev - 1);
      }

      const formattedData = data.map((datum) => ({
        ...datum,
        parent: datum?.parent ?? null,
        parentId: datum?.parentId ?? null,
      }));

      setMeta(meta);
      setTopics(formattedData);
    } catch (error) {
      toast('Failed to fetch topics. Please try again later.', {
        type: 'error',
      });
    }
  };

  const fetchTopicOptions = async () => {
    try {
      const { data } = await Axios.get(
        `${Config.apiUrl}/api/v1/survey-topics/all`,
      );
      const options = data.data.filter((datum) => !datum.parentId);

      setAllTopics(options);
    } catch (error) {
      toast('Failed to fetch topic options, Please try again later.', {
        type: 'error',
      });
    }
  };

  const onUpdate = (role) => {
    if (showOrganizationAlert) return;

    setSelectedTopic(role);
    if (role.parentId) {
      setisChildTopic(1);
    }
    setShowUpdateModal(true);
  };

  const onAdd = () => {
    if (showOrganizationAlert) return;
    setSelectedTopic(null);
    setShowUpdateModal(true);
  };

  const onDelete = async (role) => {
    if (showOrganizationAlert) return;
    setSelectedTopic(role);
    setShowDeleteModal(true);
  };

  const deleteTopic = async () => {
    try {
      await Axios.delete(
        `${Config.apiUrl}/api/v1/survey-topics/${selectedTopic?._id}`,
      );

      setTopics((prevTopics) =>
        prevTopics.filter((o) => o._id !== selectedTopic?._id),
      );
      setSelectedTopic(initialTopic);
      setShowDeleteModal(false);

      await fetchData();
      await fetchTopicOptions();
      toast('Topic deleted successfully');
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  const handleUpdateTopic = (event) => {
    let value = event.target.value;
    let key = event.target.name;

    setSelectedTopic({
      ...selectedTopic,
      [key]: value,
    });
  };

  const handleRadioButton = (event) => {
    if (!event.target) return;

    setisChildTopic(Number(event.target.value));
    setSelectedTopic({
      ...selectedTopic,
      parentId: null,
      parent: '',
    });
  };

  const updateTopic = async (event) => {
    event.preventDefault();

    try {
      if (!selectedTopic?._id) {
        await addTopic();
      } else {
        await Axios.patch(
          `${Config.apiUrl}/api/v1/survey-topics/${selectedTopic?._id}`,
          {
            title: selectedTopic?.title,
            parent: selectedTopic?.parentId,
            category: selectedTopic?.category,
            organization: currentUser?.organization?._id,
          },
        );

        setTopics((prev) => {
          let index = prev.findIndex((o) => o._id === selectedTopic?._id);

          prev[index] = {
            ...selectedTopic,
          };

          return prev;
        });

        toast('Onderwerp succesvol bijgewerkt');
      }

      await fetchData();
      await fetchTopicOptions();

      setSelectedTopic(initialTopic);
      setShowUpdateModal(false);
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  const onDragCompleted = async (data) => {
    try {
      if (!data.length) return;

      const arrangedData = data.map((item) => {
        const { _id, ...rest } = item;
        return {
          _id: _id,
          updateData: rest,
        };
      });

      await Axios.post(
        `${Config.apiUrl}/api/v1/survey-topics/bulk-update`,
        arrangedData,
      );

      toast('Onderwerpen succesvol bijgewerkt');

      await fetchData();
      await fetchTopicOptions();
    } catch (error) {
      toast(error?.response?.data?.message, {
        type: 'error',
      });
    }
  };

  const closeUpdateModal = () => {
    setShowUpdateModal(false);
    setSelectedTopic(initialTopic);
    setisChildTopic(0);
  };

  const isFormValid = () => {
    if (selectedTopic?.title && selectedTopic?.category && isChildTopic === 0)
      return true;

    if (
      selectedTopic?.title &&
      selectedTopic?.category &&
      selectedTopic?.parentId &&
      isChildTopic === 1
    )
      return true;

    return false;
  };

  useEffect(() => {
    if (currentUser) {
      fetchData();

      if (currentUser.role.name === 'admin' && !currentUser?.organization) {
        setShowOrganizationAlert(true);
      }
    }
    // eslint-disable-next-line
  }, [page, limit]);

  useEffect(() => {
    fetchTopicOptions();
  }, []);

  useEffect(() => {
    setTopicOptions(
      allTopics.filter((topic) => topic._id !== selectedTopic?._id),
    );
  }, [allTopics, selectedTopic]);

  return (
    <div className="h-screen pt-32 px-4">
      {showOrganizationAlert && <AlertMessage message={warningMessage} />}
      <div
        className={`${
          showOrganizationAlert && 'opacity-60 pointer-events-none select-none'
        }`}
      >
        <div className="flex justify-end">
          <button className="auth-button w-[200px] mb-4" onClick={onAdd}>
            Toevoegen
          </button>
        </div>
      </div>
      {showUpdateModal && (
        <Modal
          action={selectedTopic?._id ? 'Update' : 'Aanmaken'}
          title="Onderwerp"
          close={() => closeUpdateModal()}
        >
          <form className="flex flex-col space-y-4" onSubmit={updateTopic}>
            <input
              name="title"
              className="min-w-full text-black rounded input bg-transparent"
              placeholder="Voer de naam van de onderwerp in"
              value={selectedTopic?.title ?? ''}
              onChange={handleUpdateTopic}
              required
            />

            <select
              className="select rounded min-w-full"
              name="category"
              value={selectedTopic?.category ?? ''}
              onChange={handleUpdateTopic}
              required
            >
              <option value="">Selecteer vragenlijst</option>
              {category.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.name}
                </option>
              ))}
            </select>

            <div id="topicType" className="!ml-0 flex gap-3">
              <label className="flex cursor-pointer gap-2">
                <input
                  type="radio"
                  className="radio radio-success"
                  name="topicType"
                  checked={isChildTopic === 0}
                  onChange={handleRadioButton}
                  value={0}
                />
                Parent
              </label>
              <label className="flex cursor-pointer gap-2">
                <input
                  type="radio"
                  className="radio radio-success"
                  name="topicType"
                  onChange={handleRadioButton}
                  checked={isChildTopic === 1}
                  value={1}
                />
                Child
              </label>
            </div>

            {isChildTopic === 1 && (
              <select
                className="select rounded min-w-full"
                value={selectedTopic?.parentId ?? ''}
                name="parentId"
                onChange={handleUpdateTopic}
                required
              >
                <option value="">Select parent topic</option>
                {topicOptions.map((item, index) => (
                  <option key={index} value={item._id}>
                    {item.title}
                  </option>
                ))}
              </select>
            )}

            <div className="flex justify-between">
              <button
                type="button"
                className="auth-button w-[100px]"
                onClick={() => closeUpdateModal()}
              >
                Cancel
              </button>

              <button
                type="submit"
                className="auth-button w-[100px]"
                disabled={!isFormValid()}
              >
                {selectedTopic?._id ? 'Update' : 'Create'}
              </button>
            </div>
          </form>
        </Modal>
      )}

      {showDeleteModal && (
        <Modal
          title="Onderwerp"
          action="Verwijderen"
          close={() => setShowDeleteModal(false)}
          confirm={deleteTopic}
          type="delete"
          name={selectedTopic?.title}
        />
      )}
      <DraggableTable
        fields={fields}
        data={topics}
        setData={setTopics}
        handleDragCompleted={onDragCompleted}
        actions={{ onUpdate, onDelete }}
        hasPagination={true}
        page={page}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        meta={meta}
        className={
          showOrganizationAlert && 'opacity-60 pointer-events-none select-none'
        }
        ignoredFields={['parentId', 'category', 'order', '_id']}
      />
    </div>
  );
};
export default Topic;

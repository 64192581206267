import {
  faPen,
  faTrash,
  faGripVertical,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StaticTableRow = ({
  ignoredFields,
  index,
  keys,
  hideActions,
  item,
  eAF,
  data,
}) => {
  return (
    <tr className="sand-background outline outline-1 outline-black shadow-lg">
      <td className="!px-[16px]">
        <FontAwesomeIcon
          className="text-xl cursor-grabbing mr-2"
          icon={faGripVertical}
        />
        {index + 1}
      </td>
      {keys?.map((key) => {
        return (
          !ignoredFields.includes(key) && <td key={key}>{item[key] ?? '-'}</td>
        );
      })}
      <td className="!text-right !px-[16px]">
        {eAF && eAF?.length > 0 && eAF.map((field) => field(data[index]))}
        {!hideActions && (
          <>
            <button className="mx-4">
              <FontAwesomeIcon className="text-xl" icon={faPen} />
            </button>
            <button>
              <FontAwesomeIcon className="text-xl" icon={faTrash} />
            </button>
          </>
        )}
      </td>
    </tr>
  );
};

export default StaticTableRow;

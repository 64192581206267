import React, { useEffect, useState } from 'react';
import Timer from '../img/timer.png';
import Heart from '../img/heart.png';
import SurveyCard from '../components/survey/SurveryCard';
import AlertMessage from '../components/common/AlertMessage';
import config from '../Config';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

const content = [
  {
    image: Timer,
    text: 'Snel',
    bgColor: 'bg-yellow-300',
    category: 'quick',
    list: [
      '±5 minuten invultijd.',
      'Globaal inzicht in hoe het met je gaat.',
    ],
  },
  {
    image: Heart,
    text: 'Uitgebreid',
    bgColor: 'bg-orange-500',
    category: 'extensive',
    list: [
      '± 20 minuten invultijd.',
      'Deep dive in zelfinzicht.',
      'Gedetailleerd izicht in alle factoren die van invloed zijn op jouw veerkracht.',
      'Per factor een bijpassend advies om je veerkracht te versterken.',
      'Vindt juw beschermende factoren.',
    ],
  },
];

const Survey = ({ currentUser }) => {
  const [questionsCounts, setQuestionCounts] = useState({});
  const [showOrganizationAlert] = useState(false);
  const warningMessage =
    'Je account is nog niet gekoppeld aan een organisatie. Neem contact op met je contactpersoon om verder te gaan.';

  useEffect(() => {
    async function fetchData() {
      try {
        const {
          data: { data },
        } = await axios.get(
          `${config.apiUrl}/api/v1/survey-questions/counts?organization=${currentUser?.organization?._id}`,
        );
        setQuestionCounts(data);
      } catch (error) {
        toast('Failed to fetch categories. Please try again later.', {
          type: 'error',
        });
      }
    }

    if (currentUser) {
      fetchData();
    }
  }, [currentUser]);

  if (currentUser?.role?.name === 'super admin') {
    let organization = localStorage.getItem('sa-organization');

    if (!organization) {
      return <Redirect to="/select-organization" />;
    }

    return (
      <div className="pb-20 pt-36 text-black overflow-auto flex justify-center items-center h-[100vh]">
        <div className="text-center flex flex-col items-center">
          <h2 className="font-bold text-3xl md:text-5xl mb-4">
            Veerkrachtscan Beheer
          </h2>
          <br />
          <br />

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 pb-20">
            <a
              href="/app/admin/organizations"
              className={`card rounded-2xl p-5 cursor-pointer shadow-lg border flex justify-center items-center hover:bg-gray-300 bg-white`}
            >
              <h1 className="text-center font-bold text-xl">
                Naar organisaties
              </h1>
              <ul class="list-disc text-left">
                <li>Organisaties aanmaken en beheren</li>
                <li>Organisatiebeheerders toevoegen</li>
              </ul>
            </a>

            <a
              href="/app/admin/projects"
              className={`card rounded-2xl p-5 cursor-pointer shadow-lg border flex justify-center items-center hover:bg-gray-300 bg-white`}
            >
              <h1 className="text-center font-bold text-xl">Naar projecten</h1>
              <ul class="list-disc text-left">
                <li>Projecten aanmaken en beheren</li>
                <li>Projectmedewerkers toevoegen</li>
              </ul>
            </a>

            <a
              href="/app/admin/users"
              className={`card rounded-2xl p-5 cursor-pointer shadow-lg border flex justify-center items-center hover:bg-gray-300 bg-white`}
            >
              <h1 className="text-center font-bold text-xl">Naar gebruikers</h1>
              <ul class="list-disc text-left">
                <li>Gebruikersbeheer</li>
                <li>Gebruikers toevoegen aan projecten</li>
                <li>Resultaten van gebruikers inzien</li>
              </ul>
            </a>
          </div>
        </div>
      </div>
    );
  }

  // Show the survey options
  return (
    <div className="pb-20 pt-36 text-black overflow-auto">
      {showOrganizationAlert && <AlertMessage message={warningMessage} />}
      <div
        className={
          showOrganizationAlert
            ? 'opacity-60 pointer-events-none select-none'
            : ''
        }
      >
        <h2 className="text-center font-bold text-3xl md:text-5xl mb-20">
          Hoe veerkrachtig ben jij?
        </h2>
        <div className="flex flex-row flex-wrap items-center justify-center  space-y-10 md:space-y-0 lg:space-x-20 gap-10">
          {questionsCounts &&
            content.map((item, index) => (
              <SurveyCard
                key={index}
                isDisabled={!questionsCounts[item.category]}
                {...item}
                index={index}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Survey;

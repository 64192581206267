import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { generatePagination, rowPerPageOptions } from '../../util/util';

const Table = ({
  fields,
  data,
  actions,
  extraActionFields: eAF,
  transform,
  transformData,
  hasPagination,
  page = 1,
  setPage,
  limit = 10,
  setLimit,
  className,
  meta,
  hideActions = false,
  ignoredFields = [],
}) => {
  const { onUpdate, onDelete } = actions;
  const [pages, setPages] = useState(null);

  const prevPage = () => {
    if (!hasPagination || page === 1) return;

    setPage((prev) => page - 1);
  };

  const nextPage = () => {
    if (!hasPagination || data?.length === 0) return;

    setPage((prev) => page + 1);
  };

  const handleRowPerPage = (e) => {
    if (setLimit) {
      setPage(1);
      setLimit(e.target.value);
    }
  };

  const renderField = ({ _id, id, ...item }, index) => {
    const keys = Object.keys(item);

    return (
      <tr key={(_id || id) + index}>
        <th className="!px-[16px]">{index + 1}</th>
        {keys?.map((key) => {
          return !ignoredFields.includes(key) && <td key={key}>{item[key]}</td>;
        })}
        <td className="!text-right !px-[16px]">
          {eAF && eAF?.length > 0 && eAF.map((field) => field(data[index]))}
          {!hideActions && (
            <>
              <button onClick={() => onUpdate(data[index])} className="mx-4">
                <FontAwesomeIcon className="text-xl" icon={faPen} />
              </button>
              <button onClick={() => onDelete(data[index])}>
                <FontAwesomeIcon className="text-xl" icon={faTrash} />
              </button>
            </>
          )}
        </td>
      </tr>
    );
  };

  useEffect(() => {
    setPages(generatePagination(page, limit, meta?.total));
    // eslint-disable-next-line
  }, [meta]);

  return (
    <div className={`flex w-full overflow-x-auto pb-0 md:pb-20 ${className}`}>
      <table className="table">
        <thead>
          <tr>
            <th className="!px-[16px]">S/N</th>
            {Object.values(fields).map((field, index) => (
              <th key={field + index}>{field}</th>
            ))}
            <th className="!text-right !px-[16px]">Acties</th>
          </tr>
        </thead>
        <tbody>
          {(transform ? transformData(data) : data).map((item, index) =>
            renderField(item, index),
          )}
        </tbody>
        {meta && hasPagination && data?.length !== 0 && (
          <tfoot>
            <tr>
              <td
                colSpan={Object.keys(fields).length + 2}
                className="text-right !px-0"
              >
                <div className="flex items-center justify-between">
                  <div className="w-full flex items-center gap-4">
                    <span>Rows per page:</span>
                    <select
                      value={limit}
                      onChange={(e) => handleRowPerPage(e)}
                      className="select rounded max-w-[120px]"
                    >
                      {rowPerPageOptions.map((item, index) => (
                        <option key={index} value={item !== 'All' ? item : ''}>
                          {item}
                          {item !== 'All' ? ' / page' : ''}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <nav
                      className="isolate inline-flex -space-x-px rounded-md mt-2"
                      aria-label="Pagination"
                    >
                      <button
                        onClick={prevPage}
                        disabled={pages?.at(0) === page}
                        className="relative h-[36px] w-[36px] inline-flex justify-center items-center rounded-full  disabled:text-gray-400 text-gray-900 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 cursor-pointer"
                      >
                        <span className="sr-only">Previous</span>
                        <svg
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>

                      {meta &&
                        pages?.map((pageNum) =>
                          typeof pageNum === 'number' ? (
                            <button
                              key={pageNum}
                              onClick={() => setPage(() => pageNum)}
                              className={`relative h-[36px] w-[36px] inline-flex justify-center items-center text-sm font-semibold focus:z-20 focus:outline-offset-0 rounded-full ${
                                page === pageNum
                                  ? 'text-gray-900 bg-gray-200'
                                  : 'hover:bg-gray-50'
                              }`}
                            >
                              {pageNum}
                            </button>
                          ) : (
                            <span
                              key={pageNum}
                              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700  focus:outline-offset-0"
                            >
                              ...
                            </span>
                          ),
                        )}

                      <button
                        onClick={nextPage}
                        disabled={pages?.at(-1) === page}
                        className="relative h-[36px] w-[36px] inline-flex justify-center items-center rounded-full  disabled:text-gray-400 text-gray-900  hover:bg-gray-50 focus:z-20 focus:outline-offset-0 cursor-pointer"
                      >
                        <span className="sr-only">Next</span>
                        <span> {data?.length < limit}</span>
                        <svg
                          className="h-5 w-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </nav>
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default Table;

import React, { useEffect, useState, useMemo } from 'react';
import { generatePagination, rowPerPageOptions } from '../../util/util';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

import DraggableTableRow from './DraggableTableRow';
import StaticTableRow from './StaticTableRow';

function DraggableTable({
  fields,
  data,
  setData,
  actions,
  extraActionFields: eAF,
  hasPagination,
  transformData,
  transform,
  page = 1,
  setPage,
  limit = 10,
  setLimit,
  className,
  meta,
  hideActions = false,
  ignoredFields = [],
  handleDragCompleted,
}) {
  const { onUpdate, onDelete } = actions;
  const [pages, setPages] = useState(null);
  const [activeId, setActiveId] = useState(null);

  const prevPage = () => {
    if (!hasPagination || page === 1) return;

    setPage((prev) => page - 1);
  };

  const nextPage = () => {
    if (!hasPagination || data?.length === 0) return;

    setPage((prev) => page + 1);
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id && setData) {
      let changes = [];

      // Find the dragged item and the target item
      const draggedItem = data.find((item) => item._id === active.id);
      const targetItem = data.find((item) => item._id === over.id);

      if (!draggedItem || !targetItem) {
        setActiveId(null);
        return;
      }

      // Determine new order value for dragged item
      const newOrder = targetItem.order;

      // Update orders
      const updatedItems = data.map((item) => {
        if (item._id === draggedItem._id) {
          // Set new order for the dragged item
          return { ...item, order: newOrder };
        } else if (
          // Shift other items' orders based on the drag direction
          draggedItem.order < newOrder &&
          item.order > draggedItem.order &&
          item.order <= newOrder
        ) {
          return { ...item, order: item.order - 1 };
        } else if (
          draggedItem.order > newOrder &&
          item.order < draggedItem.order &&
          item.order >= newOrder
        ) {
          return { ...item, order: item.order + 1 };
        }
        return item;
      });

      changes = updatedItems.sort((a, b) => a.order - b.order);

      setData(changes);
      handleDragCompleted(changes);
      setActiveId(null);
    }
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  const handleRowPerPage = (e) => {
    if (setLimit) {
      setPage(1);
      setLimit(e.target.value);
    }
  };

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }

    const tranformedData = transform ? transformData(data) : data;
    const row = tranformedData.find((item) => item._id === activeId);
    const index = tranformedData.findIndex((item) => item._id === activeId);

    return { row, index };
  }, [activeId, data, transform, transformData]);

  const renderField = (item, index) => {
    const keys = Object.keys(item);

    return (
      <DraggableTableRow
        key={item?._id}
        item={item}
        index={index}
        keys={keys}
        data={data}
        hideActions={hideActions}
        eAF={eAF}
        onUpdate={onUpdate}
        onDelete={onDelete}
        ignoredFields={ignoredFields}
      />
    );
  };

  useEffect(() => {
    setPages(generatePagination(page, limit, meta?.total));
    // eslint-disable-next-line
  }, [meta]);

  return (
    <div className={`flex w-full overflow-x-auto pb-0 md:pb-20 ${className}`}>
      <DndContext
        sensors={sensors}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
      >
        <table className="table">
          <thead>
            <tr>
              <th className="!px-[16px]">S/N</th>
              {Object.values(fields).map((field, index) => (
                <th key={field + index}>{field}</th>
              ))}
              <th className="!text-right !px-[16px]">Acties</th>
            </tr>
          </thead>
          <tbody>
            <SortableContext
              items={data}
              strategy={verticalListSortingStrategy}
            >
              {(transform ? transformData(data) : data).map((item, index) =>
                renderField(item, index),
              )}
            </SortableContext>
          </tbody>

          {meta && hasPagination && data?.length !== 0 && (
            <tfoot>
              <tr>
                <td
                  colSpan={Object.keys(fields).length + 2}
                  className="text-right !px-0"
                >
                  <div className="flex items-center justify-between">
                    <div className="w-full flex items-center gap-4">
                      <span>Rows per page:</span>
                      <select
                        value={limit}
                        onChange={(e) => handleRowPerPage(e)}
                        className="select rounded max-w-[120px]"
                      >
                        {rowPerPageOptions.map((item, index) => (
                          <option
                            key={index}
                            value={item !== 'All' ? item : ''}
                          >
                            {item}
                            {item !== 'All' ? ' / page' : ''}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <nav
                        className="isolate inline-flex -space-x-px rounded-md mt-2"
                        aria-label="Pagination"
                      >
                        <button
                          onClick={prevPage}
                          disabled={pages?.at(0) === page}
                          className="relative h-[36px] w-[36px] inline-flex justify-center items-center rounded-full  disabled:text-gray-400 text-gray-900 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 cursor-pointer"
                        >
                          <span className="sr-only">Previous</span>
                          <svg
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>

                        {meta &&
                          pages?.map((pageNum) =>
                            typeof pageNum === 'number' ? (
                              <button
                                key={pageNum}
                                onClick={() => setPage(() => pageNum)}
                                className={`relative h-[36px] w-[36px] inline-flex justify-center items-center text-sm font-semibold focus:z-20 focus:outline-offset-0 rounded-full ${
                                  page === pageNum
                                    ? 'text-gray-900 bg-gray-200'
                                    : 'hover:bg-gray-50'
                                }`}
                              >
                                {pageNum}
                              </button>
                            ) : (
                              <span
                                key={pageNum}
                                className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700  focus:outline-offset-0"
                              >
                                ...
                              </span>
                            ),
                          )}

                        <button
                          onClick={nextPage}
                          disabled={pages?.at(-1) === page}
                          className="relative h-[36px] w-[36px] inline-flex justify-center items-center rounded-full  disabled:text-gray-400 text-gray-900  hover:bg-gray-50 focus:z-20 focus:outline-offset-0 cursor-pointer"
                        >
                          <span className="sr-only">Next</span>
                          <span> {data?.length < limit}</span>
                          <svg
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                      </nav>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
        <DragOverlay>
          {activeId && (
            <table className="table">
              <tbody>
                <StaticTableRow
                  item={selectedRow?.row}
                  index={selectedRow?.index}
                  keys={Object.keys(selectedRow?.row) ?? null}
                  data={data}
                  hideActions={hideActions}
                  eAF={eAF}
                  ignoredFields={ignoredFields}
                />
              </tbody>
            </table>
          )}
        </DragOverlay>
      </DndContext>
    </div>
  );
}

export default DraggableTable;
